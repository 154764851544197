import { selectCourseEnabled } from "@/store/slices/auth";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const RedirectHome = (props: { children: JSX.Element }) => {
  const router = useRouter();
  const courseEnabled = useSelector(selectCourseEnabled);

  useEffect(() => {
    if (router.asPath !== "/") return;

    if (courseEnabled) {
      router.replace("/home", undefined, { shallow: true })
      return;
    }
  }, [courseEnabled]);


  return props.children;
};

export default RedirectHome;